import { render, staticRenderFns } from "./CheckoutBillingAddress.vue?vue&type=template&id=2259faba&scoped=true&"
import script from "./CheckoutBillingAddress.vue?vue&type=script&lang=js&"
export * from "./CheckoutBillingAddress.vue?vue&type=script&lang=js&"
import style0 from "./CheckoutBillingAddress.vue?vue&type=style&index=0&id=2259faba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2259faba",
  null
  
)

export default component.exports